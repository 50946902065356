<template>
  <section class="cont">
    <el-row class="crumbs-box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>消息管理</el-breadcrumb-item>
        <el-breadcrumb-item>小程序版本管理</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="content-box">
      <el-tabs v-model="activeName">
        <el-tab-pane label="小程序版本管理" name="versions"></el-tab-pane>
        <el-tab-pane label="小程序模块管理" name="module"></el-tab-pane>
      </el-tabs>
      <!-- 查询框   -->
      <template v-if="activeName == 'versions' ">

        <el-row class="search-box">
          <el-row class="search-row">
            <el-row class="search-item">
              <label>商户名称：</label>
              <el-input class="width-180" clearable placeholder="商户名称" v-model="appName">
              </el-input>
            </el-row>
            <!--                  <el-row class="search-item">-->
            <!--                     <label>小程序名称：</label>-->
            <!--                     <el-input class="width-180" clearable placeholder="小程序名称" v-model="appName">-->
            <!--                     </el-input>-->
            <!--                  </el-row>-->
            <!--                  <el-row class="search-item">-->
            <!--                     <label>当前版本：</label>-->
            <!--                     <el-select v-model="state" clearable  class="width-150" :placeholder="$t('msg.select')">-->
            <!--                        <el-option-->

            <!--                              v-for="item in Updatestatuslist"-->
            <!--                              :key="item.status"-->
            <!--                              :label="item.label"-->
            <!--                              :value="item.status">-->
            <!--                        </el-option>-->
            <!--                     </el-select>-->
            <!--                  </el-row>-->
            <el-row class="search-item">
              <label>升级状态：</label>
              <el-select v-model="state" clearable class="width-180" :placeholder="$t('msg.select')">
                <el-option v-for="(item,index) in dictData['applet-audit-state']" :key="index" :label="item" :value="index">
                </el-option>
              </el-select>
            </el-row>
            <el-button type="primary bg-gradient" @click="getTemplateLast">搜索</el-button>
            <el-button type="primary bg-gradient" @click="reload()">刷新审核状态</el-button>

          </el-row>
        </el-row>
        <!-- 主体内容 -->
        <el-row class="table-box">
          <!-- 表格按钮 -->
          <div class="table-head-btn">
            <el-row>
            </el-row>
            <el-row class="right-btn">
              <el-button type="primary bg-gradient" :disabled=" this.multipleSelection == '' ">批量上传</el-button>
              <el-button class="bg-gradient" :disabled=" this.multipleSelection = '' " type="primary">批量发布</el-button>
            </el-row>
          </div>
          <!-- 表格 -->
          <el-table ref="multipleTable" :data="confinglist" tooltip-effect="dark" border fit @selection-change="handleSelectionChange">
            <el-table-column type="selection"></el-table-column>
            <el-table-column label="序号" width="60">
              <template slot-scope="scope">{{scope.$index+1}}</template>
            </el-table-column>
            <el-table-column prop="appName" label="商户名称" width="150" />
            <el-table-column prop="appId" label="授权APPID" width="180" />
            <el-table-column prop="version" label="当前版本" />
            <el-table-column prop="userVersion" label="最新版本" />
            <el-table-column prop="bedNum" label="上一版本" align="center">
              <el-table-column prop="extra.prevVersion" label="版本号" min-width="80" />
              <el-table-column prop="extra.prevReleaseTime" label="发布时间" width="160" />
            </el-table-column>
            <el-table-column prop="roomTotalNum" label="本次升级版本" align="center">
              <el-table-column prop="extra.currVersion" label="版本号" />
              <el-table-column prop="extra.currAuditTime" label="上传待审时间" width="160" />
              <el-table-column prop="auditTime" label="审核时间" width="160" />
              <el-table-column prop="releaseTime" label="发布时间" width="160" />
            </el-table-column>
            <el-table-column prop="roomTypePrice" label="升级状态" width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.state | filterState(that) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="address" min-width="200" label="操作">
              <template slot-scope="scope">
                <el-button type="text" v-if="scope.row.state == 'AUDIT' || scope.row.state == 'UPLOAD_CODE' || scope.row.state == 'SUCCESS' || scope.row.state == 'FAIL'  " @click="getqrcodeMap(scope.row.appId)">
                  预览
                </el-button>
                <!--                    v-if="scope.row.state == 'RELEASE' "-->
                <el-button type="text" @click="commitCode(scope.row.appId,'code')">
                  上传代码
                </el-button>
                <el-button type="text" v-if="scope.row.state == 'AUDIT' || scope.row.state == 'FAIL' || scope.row.state == 'UPLOAD_CODE'  " @click="uploadingmaterial(scope.row.appId)">
                  提审
                </el-button>
                <el-button type="text" v-if="scope.row.state == 'SUCCESS' " @click="commitCode(scope.row.appId,'issue',scope.row.userVersion)" style="margin-right: 15px">
                  发布
                </el-button>
                <el-dropdown trigger="click">
                  <el-button type="text">
                    更多
                  </el-button>
                  <!--                     {status:'UPLOAD_CODE',label:'待审核'}, 预览、提审、回退版本、初始业务域名-->
                  <!--                     {status:'SUCCESS',label:'审核通过'}, 预览、刷新、回退版本、初始业务域名-->
                  <!--                     {status:'FAIL',label:'审核拒绝'},  预览、提审、回退版本、初始业务域名-->
                  <!--                     {status:'AUDIT',label:'审核中'}, 预览、提审、撤回审核、加急审核、初始业务域名-->
                  <!--                     {status:'REVOKE',label:'已撤回'},-->
                  <!--                     {status:'DELAY',label:'审核延后'},-->
                  <!--                     {status:'RELEASE',label:'已发布'}, 上传历史代码、回退版本、初始业务域名-->
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-if="scope.row.state == 'RELEASE' " @click.native="dialogTableVisible = true">上传历史版本</el-dropdown-item>
                    <el-dropdown-item v-if="scope.row.state == 'AUDIT' " @click.native="commitCode(scope.row.appId,'recall')">撤回审核</el-dropdown-item>
                    <el-dropdown-item v-if="scope.row.state == 'AUDIT' " @click.native="commitCode(scope.row.appId,'urgent')">加急审核</el-dropdown-item>
                    <!--                        <el-dropdown-item @click="cancel(scope.row)">升级历史</el-dropdown-item>-->
                    <el-dropdown-item v-if="scope.row.state == 'RELEASE' || scope.row.state == 'UPLOAD_CODE' || scope.row.state == 'SUCCESS' || scope.row.state == 'FAIL'  " @click.native="commitCode(scope.row.appId,'rollback',scope.row.extra.prevVersion)">回退版本</el-dropdown-item>
                    <el-dropdown-item v-if="scope.row.state == 'RELEASE' || scope.row.state == 'AUDIT' || scope.row.state == 'UPLOAD_CODE' || scope.row.state == 'SUCCESS' || scope.row.state == 'FAIL' " @click.native="initializedconfing(scope.row)">初始业务域名</el-dropdown-item>
                    <el-dropdown-item @click.native="queryInterface(scope.row.appId)">查询接口</el-dropdown-item>
                    <el-dropdown-item @click.native="applyPpermission(scope.row.appId)">申请权限</el-dropdown-item>
                    <el-dropdown-item @click.native="privacyAgreement(scope.row.appId)">隐私保护协议</el-dropdown-item>

                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>

          <!-- 分页 -->
          <pagination :total="total" :page-size="limit" :current-page="page" v-if="pageshow" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging" />
        </el-row>
      </template>
      <!-- 小程序模块管理 -->
      <template v-else>
        <!-- 表格 -->
        <h3>草稿箱</h3>

        <el-table ref="draft" :data="template" tooltip-effect="dark" border fit>

          <el-table-column prop="user_version" label="版本号" width="80" />
          <el-table-column prop="user_desc" label="描述">
            <template slot-scope="scope">
              <el-input type="textarea" v-model="scope.row.user_desc" :rows="5"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="create_time" label="创建时间" />
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="addTemplate(scope.row.draft_id)">
                添加到模板库
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <h3>模板库</h3>

        <el-table ref="multipleTable" :data="templatelist" tooltip-effect="dark" border fit>
          <el-table-column prop="user_version" label="版本号" width="80">
          </el-table-column>
          <el-table-column prop="user_desc" label="描述">
            <template slot-scope="scope">
              <p v-for="(item,index) in scope.row.user_desc" :key="index"> {{item}} </p>

            </template>
          </el-table-column>
          <el-table-column prop="create_time" label="最近提交时间" width="180" />
          <el-table-column prop="version" label="操作" width="100">
            <template slot-scope="scope">
              <el-button type="text" @click="deleteTemplate(scope.row)">
                删除模块
              </el-button>
            </template>

          </el-table-column>

        </el-table>
      </template>
      <!--历史版本-->
      <el-dialog title="历史版本" :visible.sync="dialogTableVisible">
        <el-table :data="templatelist" height="450">
          <el-table-column label="选择" width="60">
            <template slot-scope="scope">
              <el-radio :label="scope.$index+1" :disabled="scope.row.editshow" v-model="radio" @change.native="getCurrentRow(scope.row)"></el-radio>
            </template>
          </el-table-column>
          <el-table-column property="user_version" label="版本号" width="80"></el-table-column>
          <el-table-column property="user_desc" label="描述"></el-table-column>
          <el-table-column property="create_time" label="添加到模板库时间" width="150"></el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="uploadhistory()">确 认</el-button>
          <el-button @click="dialogTableVisible = false">关 闭</el-button>
        </span>
      </el-dialog>
      <!--查看体验码-->
      <el-dialog title="查看体验码" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
        <img class="qrcodeMap" referrerPolicy="no-referrer" :src="qrcodeMap" />

        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="getqrcodeMap('','upload')">下 载</el-button>
          <el-button @click="dialogVisible = false">关 闭</el-button>
        </span>
      </el-dialog>
      <!--上传素材-->
      <el-dialog title="上传素材" :visible.sync="uploaddialogVisible" width="50%" :before-close="handleClose">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="辅审图片:" prop="requestdomain">
            <el-row class="flex-pic" v-if="isRefresh">
              <img width="148" height="148" class="old-pic" v-if="isEcho" :src="image" alt="">
              <el-upload ref="posterupload" :limit="5" :show-file-list="true" action="#" :auto-upload="false" list-type="picture-card" :on-change="handleChangeposter" :on-preview="handlePictureCardPreview">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog title="查看图片" :visible.sync="imgdialogVisible">
                <img width="100%" :src="image" alt="">
              </el-dialog>
            </el-row>
          </el-form-item>
          <el-form-item label="辅审视频:" prop="requestdomain">
            <div class="img-box" v-if="imgboxshow" style="display: flex;margin-bottom: 20px">
              <div class="img-list" v-for="(item,index) in  videolist" :key="index" style="position: relative;margin: 10px;">
                <video :src="item.video" style="width: 145px;height: 145px;cursor: pointer;" controls />
                <div class="delicon" @click="deleteimg(item.id,index)">×</div>
              </div>
            </div>
            <el-upload class="avatar-uploader" action="" :limit="5" ref="upload" :show-file-list="false" :on-change="handleChange" :auto-upload="false" :multiple="false" style="z-index: 999">
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="版本说明：">
            <el-input type="textarea" v-model="formdata.describe" :rows="5"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="uploaddialogVisible = false">关 闭</el-button>
          <el-button type="primary" :disabled="disabled" @click="submitAudit">确 定 上 传</el-button>

        </span>
      </el-dialog>
      <!--初始业务域名-->
      <el-dialog title="额外配置" :visible.sync="addroomVisible" width="60%" :before-close="handleClose">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="180px" class="demo-ruleForm">
          <h2>业务域名配置</h2>
          <el-form-item label="Request合法域名:" prop="requestdomain">
            <el-input v-model="ruleForm.requestdomain"></el-input>
          </el-form-item>
          <el-form-item label="Socket合法域名：" prop="wsrequestdomain">
            <el-input v-model="ruleForm.wsrequestdomain"></el-input>
          </el-form-item>
          <el-form-item label="UploadFile合法域名：" prop="uploaddomain">
            <el-input v-model="ruleForm.uploaddomain"></el-input>
          </el-form-item>
          <el-form-item label="DownloadFile合法域名：" prop="downloaddomain">
            <el-input v-model="ruleForm.downloaddomain"></el-input>
          </el-form-item>
          <el-form-item label="类型：" prop="action">
            <template>
              <el-radio v-model="ruleForm.action" v-for="(item,index) in actionlist" :key="index" :label="item.type">{{item.label}}</el-radio>
            </template>
          </el-form-item>
          <el-form-item label="" prop="action" align="right">
            <el-button @click="addroomVisible = false">关 闭</el-button>
            <el-button type="primary" @click="setAppletDomain">确 定</el-button>
          </el-form-item>

          <h2>业务插件配置</h2>
          <el-form-item label="pluginAppId：" prop="action">
            <span>wxfa43a4a7041a84de</span>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="addroomVisible = false">关 闭</el-button>
          <el-button type="primary" @click="appletPlugin">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 查询接口dialog框 -->
      <el-dialog title="查询接口结果" :visible="visibleInterface" @close="visibleInterface = false">
        <div>
          <pre>
               {{InterfaceDetails}}
            </pre>
        </div>
      </el-dialog>
    </el-row>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { urlObj } from "@/api/interface";
import { appletVersion } from "@/api/interface/system";
import { dateFactory, getDict } from "@/common/js/common";
export default {
  data() {
    return {
      that: this,
      confinglist: [],
      Updatestatuslist: [
        { status: "UPLOAD_CODE", label: "上传模板代码" },
        { status: "SUCCESS", label: "审核通过" },
        { status: "FAIL", label: "审核拒绝" },
        { status: "AUDIT", label: "审核中" },
        { status: "REVOKE", label: "已撤回" },
        { status: "DELAY", label: "审核延后" },
        { status: "RELEASE", label: "已发布" },
      ],
      state: "",
      total: 0,
      limit: 10,
      qrcodeMap: "",
      image: "",
      imagelist: "",
      appName: "",
      activeName: "versions",
      pageshow: true,
      dialogVisible: false,
      uploaddialogVisible: false,
      imgdialogVisible: false,
      dialogTableVisible: false,
      isEcho: false,
      isRefresh: true,
      imgboxshow: false,
      videolist: [],
      version: "",
      radio: false,
      template: [], //草稿模板列表
      templatelist: [], //模块列表
      param: "",
      url: "",
      disabled: false,
      appId: "",
      describe: "", //提审描述
      addroomVisible: false,
      visibleInterface: false, // 查询接口
      InterfaceDetails: "",
      multipleSelection: [],
      updateform: "",
      formdata: {
        preview_info: {
          video_id_list: [],
          pic_id_list: [],
        },
        version_desc: "",
      },
      actionlist: [
        { type: "add", label: "添加" },
        { type: "delete", label: "删除" },
        { type: "set", label: "覆盖" },
        { type: "get", label: "获取" },
      ],
      blob: "",
      page: 1,
      btnshow: false,
      ruleForm: {
        action: "add",
        wsrequestdomain: "",
        uploaddomain: "",
        requestdomain: "https://aiot.dayizhu.cn",
        downloaddomain: "https://aiot.dayizhu.cn",
        pluginAppId: "",
      },
      rules: {
        floor: [
          {
            required: true,
            message: "请选择楼层",
            trigger: "blur",
          },
        ],
        roomNo: [
          {
            required: true,
            message: "请输入房号",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState(["hotelInfo", "dictData"]),
  },
  mounted() {
    this.limit = sessionStorage.getItem("pageSize");
    getDict(["applet-audit-state"]);
    if (this.hotelRes_info === "applet-audit-state") {
      delete this.param.orderStatusList;
    } else {
      delete this.param.orderStatus;
    }
    this.getTemplateLast();
  },
  watch: {
    hotelInfo: {
      handler(newVal, oldVal) {
        if (newVal.id !== oldVal.id && oldVal) {
          this.hotelId = this.hotelInfo.id;
          this.getTemplateLast();
        }
      },
    },
  },
  filters: {
    filterState(val, that) {
      if (val && that.dictData["applet-audit-state"])
        return that.dictData["applet-audit-state"][val] || val;
    },
  },
  methods: {
    //获取草稿模板
    getTemplateLast() {
      const url = appletVersion.getTemplateLast;
      const param = {};
      this.$axios.get(url, param).then((res) => {
        this.template = res.records.draft_list;
        this.template.forEach((item) => {
          item.create_time = dateFactory.timestampFormat(
            item.create_time,
            true
          );
        });
        this.getconfinglist();
      });
    },
    //获取模板id
    getminiTemplateList() {
      const url = appletVersion.getminiTemplateList;
      const param = {
        // componentAppId:'wxde771f6819a92acc',
        draftId: this.template.draft_id,
      };
      this.$axios.get(url, param).then((res) => {
        this.templatelist = res.records.template_list;
        this.templatelist.forEach((item) => {
          item.create_time = dateFactory.timestampFormat(
            item.create_time,
            true
          );
          item.user_desc = item.user_desc.split(/[;；]/);
        });
        this.templatelist = this.templatelist.reverse();
      });
    },
    //营销内容表格单选框事件
    getCurrentRow(val) {
      this.param = {
        template_id: val.template_id,
        user_version: val.user_version,
        user_desc: val.user_desc,
        ext_json: {
          extEnable: false,
          extAppid: this.appId,
        },
      };
    },
    reload() {
      window.location.reload();
    },
    //上传历史版本
    uploadhistory() {
      this.url = appletVersion.commitCode + `?appId=${this.appId}`;
      this.$axios.post(this.url, this.param, "json").then((res) => {
        if (res.success) {
          this.$message({
            showClose: true,
            message: "操作成功！",
            type: "success",
          });
          this.updateVersion("code");
          this.getconfinglist();
        }
      });
    },
    //将草稿添加到模板库
    addTemplate(id) {
      const url = appletVersion.addTemplate;
      const param = {
        // componentAppId:'wxde771f6819a92acc',
        draftId: id,
      };
      this.$confirm("确定要将此草稿添加到模板吗?", param, {
        confirmButtonText: this.confirm,
        cancelButtonText: this.cancel,
        center: true,
      }).then(() => {
        this.$axios.post(url, param).then((res) => {
          this.$message.success("添加成功");
          this.getminiTemplateList();
        });
      });
    },
    // 改变每页数
    pageChange(num) {
      this.limit = num;
      this.getconfinglist();
    },
    // 改变当前页
    handlePaging(num) {
      this.page = num;
      this.getconfinglist();
    },
    //获取小程序列表
    getconfinglist() {
      const url =
        urlObj.EnquiryPublicAccountpage +
        `?limit=${this.limit}&page=${this.page}`;
      // const url = urlObj.EnquiryPublicAccount;
      const param = {
        cfgCode: "wechat-applet",
        appName: this.appName,
        state: this.state,
      };
      this.$axios.post(url, param, "json").then((res) => {
        if (res.success) {
          this.confinglist = res.records;
          this.total = res.total;
          this.confinglist.forEach((item) => {
            if (item.hasOwnProperty("content")) {
              item.content = JSON.parse(item.content);
            }
            if (item.hasOwnProperty("extra")) {
              item.extra = JSON.parse(item.extra);
            }
            item.userVersion = this.template[0].user_version;
          });
          this.getminiTemplateList();
        }
      });
    },
    //删除视频
    deleteimg(id, i) {
      const url = urlObj.deleteUpload;
      let param = {
        ids: id,
      };
      this.$axios.post(url, param).then((res) => {
        if (res.status == 200) {
          this.$message({
            message: "图片删除成功!",
            type: "success",
          });
        }
        this.imgboxshow = false;
        this.videolist.splice(i, 1);
        this.formdata.preview_info.video_id_list.splice(i, 1);
        if (this.videolist.length == 0) {
          this.imgboxshow = false;
        }
        this.imgboxshow = true;
      });
    },

    //视频上传
    handleChange(file) {
      this.disabled = true;

      this.file = file.raw;
      const url = urlObj.uploadDetailFile;
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("素材大小不能超过 10MB!");
        return;
      }
      let param = new FormData();
      param.append("file", this.file); // 文件
      param.append("bizId", "default");
      param.append("module", "event");
      param.append("menu", "distributor");
      param.append("func", "video");
      param.append("category", "video");
      param.append("isThumb", true);
      this.$axios
        .post(url, param, "file", { "Content-Type": "multipart/form-data" })
        .then((res) => {
          this.imgboxshow = false;
          this.videolist.push({
            video:
              window.getResourcesHost() +
              res.records.location +
              "/" +
              res.records.formatName,
            id: res.records.id,
          });
          this.imgboxshow = true;
          let param = new FormData();
          const url = appletVersion.uploadMediaAudit;
          param.append("file", this.file); // 文件
          param.append("appId", this.appId); // 文件
          this.$axios
            .post(url, param, "file", { "Content-Type": "multipart/form-data" })
            .then((res) => {
              console.log(res);
              this.formdata.preview_info.video_id_list.push(
                res.records.mediaid
              );
              this.disabled = false;
              this.$message.success("上传成功");
            });
        });
    },
    //上传素材
    handleChangeposter(file) {
      this.disabled = true;
      this.file = file.raw;
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("素材大小不能超过 10MB!");
        return;
      }
      const url = appletVersion.uploadMediaAudit;
      let param = new FormData();
      param.append("file", this.file); // 文件
      param.append("appId", this.appId); // 文件
      this.$axios
        .post(url, param, "file", { "Content-Type": "multipart/form-data" })
        .then((res) => {
          console.log(res);
          this.formdata.preview_info.pic_id_list.push(res.records.mediaid);
          this.disabled = false;
          this.$message.success("上传成功");
        });
    },

    //提交审核
    submitAudit() {
      const url = appletVersion.submitAudit + `?appId=${this.appId}`;
      const param = this.formdata;
      this.$axios.post(url, param, "json").then((res) => {
        if (res.success) {
          this.updateVersion();
          this.getminiTemplateList();
          this.uploaddialogVisible = false;
          this.formdata.preview_info.video_id_list = [];
          this.formdata.preview_info.pic_id_list = [];
          this.formdata.preview_info.version_desc = "";
        }
      });
    },
    // 更新审核状态
    updateVersion(type) {
      const url = appletVersion.updateVersion;
      if (type == "code") {
        this.updateform = {
          appId: this.appId,
          state: "UPLOAD_CODE",
          extraCfg: {
            currVersion: this.param.user_version,
          },
        };
      } else {
        this.updateform = {
          appId: this.appId,
          state: "AUDIT",
        };
      }
      const param = this.updateform;
      this.$axios.post(url, param, "json").then((res) => {
        if (res.success) {
          this.getminiTemplateList();
        }
      });
    },
    //删除指定模板
    deleteTemplate(val) {
      const url = appletVersion.deleteTemplate;
      let param = {
        // componentAppId:'wxde771f6819a92acc',
        templateId: val.template_id,
      };
      this.$axios.post(url, param).then((res) => {
        if (res.success) {
          this.$message.success("删除成功");
          this.getTemplateLast();
        }
      });
    },
    //更新酒店第三方平台应用版本发布时间
    updateTime() {
      this.param = { appId: this.appId, version: this.version };
      this.url = appletVersion.hotelupdateReleaseTime + `?appId=${this.appId}`;
      this.$axios.post(this.url, this.param).then((res) => {
        if (res.success) {
          this.$message({
            showClose: true,
            message: "操作成功！",
            type: "success",
          });
          this.param = "";
          this.version = "";
          this.url = "";
          this.getconfinglist();
        }
      });
    },
    //上传代码 /发布 /撤回 /加急 / 回退
    commitCode(id, type, version) {
      this.appId = id;
      if (type == "code") {
        this.param = {
          template_id: this.templatelist[0].template_id,
          user_version: this.templatelist[0].user_version,
          user_desc: this.templatelist[0].user_desc.join(","),
          ext_json: {
            extEnable: false,
            extAppid: this.appId,
          },
        };
        this.message = "您确定要上传最新版本代码吗?";
        this.url = appletVersion.commitCode + `?appId=${this.appId}`;
      }
      if (type == "issue") {
        this.version = version;
        this.param = { appId: this.appId, version: version };
        this.message = "您确定要发布当前审核通过的版本吗?";
        this.url = appletVersion.updateReleaseTime;
        this.$confirm(this.message, this.prompt, {
          dangerouslyUseHTMLString: true,
          confirmButtonText: this.confirm,
          cancelButtonText: this.cancel,
          center: true,
        }).then(() => {
          this.$axios.post(this.url, this.param).then((res) => {
            if (res.success) {
              this.$message({
                showClose: true,
                message: "操作成功！",
                type: "success",
              });
              this.updateTime();
              this.getconfinglist();
            }
          });
        });
        return;
      }
      if (type == "recall") {
        this.param = { appId: this.appId };
        this.message = "确定要撤回当前的审核申请吗?";
        this.url = appletVersion.recallAudit;
        this.$confirm(this.message, this.prompt, {
          dangerouslyUseHTMLString: true,
          confirmButtonText: this.confirm,
          cancelButtonText: this.cancel,
          center: true,
        }).then(() => {
          this.$axios.get(this.url, this.param).then((res) => {
            if (res.success) {
              this.$message({
                showClose: true,
                message: "操作成功！",
                type: "success",
              });
              this.updateVersion("code");
              this.getconfinglist();
            }
          });
        });
        return;
      }
      if (type == "urgent") {
        //查询加急次数
        const url = appletVersion.queryQuota;
        const param = {
          appId: this.appId,
        };
        this.$axios.get(url, param).then((res) => {
          if (res.success) {
            this.param = { appId: this.appId };
            this.message =
              "您还剩 " +
              res.records.speedup_rest +
              "次加急审核机会，确定要加急审核吗?";
            this.url = appletVersion.speedupAudit;
            this.$confirm(this.message, this.prompt, {
              dangerouslyUseHTMLString: true,
              confirmButtonText: this.confirm,
              cancelButtonText: this.cancel,
              center: true,
            }).then(() => {
              this.$axios.post(this.url, this.param).then((res) => {
                if (res.success) {
                  this.$message({
                    showClose: true,
                    message: "操作成功！",
                    type: "success",
                  });
                  this.getconfinglist();
                }
              });
            });
          }
        });
        return;
      }
      if (type == "rollback") {
        this.version = version;
        this.param = { appId: this.appId, version: version };
        this.message =
          "<p>您确定要回退版本吗？</p><p>确定后将会退回至" +
          version +
          "的版本。</p>";
        this.url = appletVersion.rollbackRelease;
        this.$confirm(this.message, this.prompt, {
          dangerouslyUseHTMLString: true,
          confirmButtonText: this.confirm,
          cancelButtonText: this.cancel,
          center: true,
        }).then(() => {
          this.$axios.get(this.url, this.param).then((res) => {
            if (res.success) {
              this.$message({
                showClose: true,
                message: "操作成功！",
                type: "success",
              });
              this.updateTime();
            }
          });
        });
        return;
      }
      this.$confirm(this.message, this.prompt, {
        dangerouslyUseHTMLString: true,
        confirmButtonText: this.confirm,
        cancelButtonText: this.cancel,
        center: true,
      }).then(() => {
        this.$axios.post(this.url, this.param, "json").then((res) => {
          if (res.success) {
            this.$message({
              showClose: true,
              message: "操作成功！",
              type: "success",
            });
            if (type == "code") {
              this.updateVersion("code");
            }

            this.getconfinglist();
          }
        });
      });
    },
    // 点击图片查看大图
    handlePictureCardPreview(file) {
      this.image = file.url;
      this.imgdialogVisible = true;
    },
    //小程序插件
    appletPlugin() {
      const url = appletVersion.appletPlugin;
      const param = {
        appId: this.appId,
        pluginAction: "APPLY",
        pluginAppId: "wxfa43a4a7041a84de",
      };
      this.$axios.post(url, param).then((res) => {
        if (res.success) {
          this.$message({
            showClose: true,
            message: "操作成功！",
            type: "success",
          });
          this.getTemplateLast();
          this.addroomVisible = false;
        }
      });
    },
    //回显初始化域名
    initializedconfing(val) {
      this.appId = val.appId;
      // this.ruleForm.wsrequestdomain = val.content.authorizer_info.MiniProgramInfo.network.WsRequestDomain.join(',')
      // this.ruleForm.uploaddomain = val.content.authorizer_info.MiniProgramInfo.network.UploadDomain.join(',')
      this.ruleForm.requestdomain =
        val.content.authorizer_info.MiniProgramInfo.network.RequestDomain.join(
          ","
        );
      this.ruleForm.downloaddomain =
        val.content.authorizer_info.MiniProgramInfo.network.DownloadDomain.join(
          ","
        );
      this.ruleForm.pluginAppId = val.appId;
      this.addroomVisible = true;
    },
    //  查询接口
    queryInterface(appId) {
      this.$axios
        .get(appletVersion.queryInterface + `?appId=${appId}`, {})
        .then((res) => {
          if (res.success) {
            this.visibleInterface = true;
            this.InterfaceDetails = JSON.stringify(res.records, null, 4);
          }
        });
    },
    //  申请权限
    applyPpermission(appId) {
      this.$axios
        .post(
          appletVersion.applyPpermission + `?appId=${appId}`,
          {
            api_name: "wx.getLocation",
            content:
              "1、提供周边搜索、位置描述、目标距离计算等服务2、提供驾车、公交、步行、骑行等路线规划服务能力",
          },
          "json"
        )
        .then((res) => {
          if (res.success) {
            this.$message.success("申请成功");
          }
        })
        .catch((err) => {
          if (err.data.message === "ok") {
            this.$message({
              type: "warning",
              message:
                "请确认此版本在发布后是否已经申请过一次权限，如果有申请权限，请不要重复申请",
            });
          }
        });
    },
    //  隐私保护协议
    privacyAgreement(appId) {
      this.$axios
        .post(
          appletVersion.privacyAgreement + `?appId=${appId}`,
          {
            owner_setting: {
              contact_email: "product1@dayizhu.cn",
              notice_method: "通过弹窗",
            },
            setting_list: [
              {
                privacy_key: "UserInfo",
                privacy_text: "登录注册小程序",
              },
              {
                privacy_key: "Location",
                privacy_text: "方便客人搜索最近的酒店，及分享酒店位置信息",
              },
              {
                privacy_key: "Address",
                privacy_text: "小程序购物后的收获地址",
              },
              {
                privacy_key: "PhoneNumber",
                privacy_text: "方便酒店与客人联系，确认订单信息",
              },
              {
                privacy_key: "EXIDNumber",
                privacy_text: "遵守法律要求，必须实名制入住酒店",
              },
              {
                privacy_key: "AlbumWriteOnly",
                privacy_text: "保存酒店海报，方便客人分享酒店信息",
              },
            ],
            privacy_ver: 2,
          },
          "json"
        )
        .then((res) => {
          if (res.success) {
            this.$message.success("操作成功");
          }
        });
    },
    //初始化域名
    setAppletDomain() {
      this.ruleForm.wsrequestdomain = this.ruleForm.wsrequestdomain.split(",");
      this.ruleForm.uploaddomain = this.ruleForm.uploaddomain.split(",");
      this.ruleForm.requestdomain = this.ruleForm.requestdomain.split(",");
      this.ruleForm.downloaddomain = this.ruleForm.downloaddomain.split(",");

      const url = appletVersion.setAppletDomain + `?appId=${this.appId}`;
      const param = {
        action: "set",
        wsrequestdomain: this.ruleForm.wsrequestdomain,
        uploaddomain: this.ruleForm.uploaddomain,
        requestdomain: [process.env.VUE_APP_BASE_API],
        downloaddomain: [process.env.VUE_APP_BASE_API],
      };
      this.$axios.post(url, param, "json").then((res) => {
        if (res.success) {
          this.addroomVisible = false;
          this.$message.success("保存成功");
          this.$refs.ruleForm.resetFields();
          this.getTemplateLast();
        }
      });
    },
    //表格选中
    handleSelectionChange(val) {
      this.multipleSelection = [];
      for (let i = 0; i < val.length; i++) {
        this.multipleSelection.push(val[i].id);
      }
    },
    //关闭弹窗
    handleClose() {
      this.dialogVisible = false;
      this.addroomVisible = false;
      this.uploaddialogVisible = false;
      this.dialogTableVisible = false;
    },
    //上传素材弹窗
    uploadingmaterial(id) {
      this.appId = id;
      this.uploaddialogVisible = true;
    },
    //查看/下载体验码
    getqrcodeMap(id, type) {
      if (id != "") this.appId = id;
      const url = appletVersion.getExperienceQrcode;
      const param = {
        appId: this.appId,
      };
      this.$axios.get(url, param, {}, "blob").then((res) => {
        if (type == "upload") {
          const blob = new Blob([res]);
          const fileName = "qrcodeMap.jpg";
          const dlink = document.createElement("a");
          if ("download" in document.createElement("a")) {
            // 非IE下载
            const dlink = document.createElement("a");
            dlink.download = fileName;
            dlink.style.display = "none";
            dlink.href = URL.createObjectURL(blob);
            document.body.appendChild(dlink);
            dlink.click();
            URL.revokeObjectURL(dlink.href); // 释放URL 对象
            document.body.removeChild(dlink);
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
          }
        } else {
          this.blob = res;
          this.qrcodeMap = window.URL.createObjectURL(res);
        }
      });

      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.cont {
  /deep/.avatar-uploader .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 148px;
    height: 148px;
    line-height: 148px;
    text-align: center;
  }
  h3 {
    padding: 25px 0;
  }
  .content-box {
    .search-row {
    }
  }
  .btn-box {
    text-align: right;
  }
  .qrcodeMap {
    width: 100%;
  }
  .uploadbtn {
    padding: 8px 20px;
    border-radius: 3px;
    position: relative;
    top: 1px;
    right: 20px;
    text-decoration: none;
  }
}
.delicon {
  color: red;
  font-size: 35px;
  position: absolute;
  right: 0;
  top: -10px;
  cursor: pointer;
}

/deep/ .cell label > .el-radio__label {
  display: none;
}

/deep/ .el-table th.gutter {
  /*
 * 解决element-ui 表格篡位的问题 👇
 */
  display: table-cell !important;
}

/deep/ .el-table--enable-row-transition .el-table__body td {
  /*text-align: center;*/
}

/deep/ .el-table th.is-leaf,
.el-table td {
  /*text-align: center;*/
  background: #f5f8f9;
}
</style>
